@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-yellow);
}

:root {
  --light-yellow: #fffb96;
  --pink: #ffcfea;
  --green: #009400;
  --red: #ff4545;
  --blue-violet: #6d5bbb;
  --black-ish: #222;
}

.bg-pink {
  background-color: var(--pink);
}

.raised {
  border: 2px solid var(--black-ish);
  box-shadow: 1px 1px var(--black-ish), 2px 2px var(--black-ish), 3px 3px var(--black-ish), 4px 4px var(--black-ish),
    5px 5px var(--black-ish);
}

.raised-sm {
  box-shadow: 1px 1px var(--black-ish), 1px 1px var(--black-ish), 2px 2px var(--black-ish), 3px 3px var(--black-ish),
    3px 3px var(--black-ish);
}

.raised-xs {
  box-shadow: 1px 1px var(--black-ish), 1px 1px var(--black-ish), 2px 2px var(--black-ish), 2px 2px var(--black-ish),
    2px 2px var(--black-ish);
}

.input {
  border: 1px solid black;
  @apply transition duration-200 ease-in-out px-2 py-1;
}

.input:focus {
  @apply raised-sm outline-none;
}

.label {
  @apply font-bold mb-1;
}

.button {
  @apply border-2 border-transparent transition duration-200 ease-in-out underline px-4 py-1 outline-none;
}

.button:focus {
  @apply outline-none;
}

.button:focus-visible,
.button:hover {
  border: 2px solid var(--black-ish);
  @apply no-underline raised-xs outline-none;
}

.car-row {
  @apply flex flex-col pb-2;
}

.car-row:not(:first-child) {
  @apply pt-2;
}

.car-row:not(:last-child) {
  border-bottom: 1px solid var(--black-ish);
}
